
import {useState} from 'react';
import {FiSearch} from 'react-icons/fi';
import './style.css';

import api from './services/api';


function App() {

  const [input, setInput] = useState('') 
  const [cep, setCep] = useState({});

  async function handleSearch(){

    // 41260025/json/

    if(input === ''){
      alert("Preencha cep")
      return;
    }
 

    try{
      const response = await api.get('$[input]/json');
     setCep(response.data)
     setInput("");

    }catch{
      alert("Ops");
    }
  }

  return (
    <div className="container">
      <h1 className="title"> Buscador de CEP</h1>

      <div className="containerInput">
        <input
        type="text"
        placeholder="Digite seu cep..."
        value={input}
        onChange={(e) => setInput(e.target.value) } />
      

          <button className="buttonSearch" onclick={handleSearch}>
           <FiSearch size={20} color={"#fff"}/>
          </button>
      </div>

      {Object.keys(cep).length > 0 && (

        <main className='main'>
          <h2>CEP: {cep.logradouro}</h2>
          <span> {cep.log}</span>
          <span> Complemento: {cep.complemento}</span>
          <span>{cep.bairro} bairro: </span>
          <span>{cep.localidade} = {cep.uf} </span>
        </main>
      )}

    </div>
  );
}

export default App;
